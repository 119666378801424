// import logo from './logo.svg';
import './App.css';
import logo from './content/img/logo.png'

function App() {
  return (
    <div className="App">
      <header className="App-header">
        {/* <video src={logo}></video> */}
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Especialistas en <code>desarrollo de software</code> a la medida.
        </p>
        <a href="https://api.whatsapp.com/send?phone=573005165832" target='_blank'>
        <img src="https://media.licdn.com/dms/image/D5612AQFU3dsb0e0-KQ/article-cover_image-shrink_423_752/0/1671216852277?e=1699488000&amp;v=beta&amp;t=jDmaYIpOQdEQX-DZ9XAfSrhWyORVv-sUj1nI_D5B498" loading="lazy" alt="" id="ember23" class="reader-cover-image__img evi-image lazy-image ember-view"></img>
        </a>
      </header>
    </div>
  );
}

export default App;
